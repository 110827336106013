
import { Component, Vue } from "vue-property-decorator";
import UserManagementService from '@/services/user-management'
import PopUp from "@/components/PopUp.vue";
import utils from "@/utils/utils"
@Component({
    components: {
        PopUp
    },
})
export default class ChangePassword extends Vue {
    error: any = '';
    confirm_password = "";
    showPasswordNew = false;
    showPassword = false;
    // For Modal
    modalShow = false;
    modalMessage: string[] = [];
    modalSimpleMessage: string = "";
    modalType = "";
    closeModal() {
        this.modalShow = false;
        this.modalMessage = [];
        this.modalType = "";
        this.modalSimpleMessage = ""
    }
    showModal(message: string, array: string[], type: "success" | "error" | "errorArray",) {
        this.modalShow = true;
        this.modalMessage = array;
        this.modalType = type;
        this.modalSimpleMessage = message
    }
    // modal end
    public passwordObject: any = {}
    forgetPassword() {
        const error_list = utils.getEmptyKeys(this.passwordObject, ['new_password'])
        if (error_list.length > 0 || this.confirm_password === "") {
            this.error = utils.generateErrorMessage(error_list.concat(this.confirm_password === "" ? 'Confrim Passwrod' : ""))
        }
        else {
            if (this.confirm_password === this.passwordObject.new_password) {
                let path = window.location.href
                let hashing = path.split('password-update')[1]
                console.log(hashing)
                let parts = hashing.split('/')
                console.log(parts)
                UserManagementService.resetPassword(this.passwordObject, parts[1], parts[2]).then((resp) => {
                    this.resetFields();
                    console.log(resp)
                    if (resp.data.result == "ok") {
                        this.showModal("Password Changed Successfully", [], "success");
                    }
                })
                    .catch((e) => {
                        console.log('Unexpected error:', e);
                        this.showModal('An unexpected error occurred', [], "error");
                    });
            }
            else {
                this.error = "Password not matched"
            }
        }
    };
    resetFields() {
        this.passwordObject.password = '';
    }
    togglePasswordVisibilityNew() {
        this.showPasswordNew = !this.showPasswordNew;
    }
    togglePasswordVisibility() {
        this.showPassword = !this.showPassword;
    }
}
